@use '../config/' as *;
.waf-playlist-section,
.waf-playlist-detail {
  padding-top: var(--two-space);
  &.waf-listing {
    margin-block: 0;
  }
  .article-section {
    &-head {
      @extend %m-b-two-space;
      @extend %flex-sb-c;
      @extend %gap-half;
    }
    &-more a {
      width: max-content;
      @extend %d-block;
      @extend %font-16-pb;
    }
  }
  .title,
  .article-section-title {
    color: var(--c-white);
    @extend %m-y-zero;
    @extend %capitalize;
  }
  .article-section-wrapper {
    @extend %m-b-three-space;
  }
}
.waf-playlist-detail {
  @extend %c-blue-3-bg-10;
  .article-list {
    overflow-x: unset;
    @extend %flex-column;
    > * {
      width: 100%;
    }
  }
}
.playlist-management-body {
  .breadcrumb-wrap {
    position: unset;
    @extend %d-block;
  }
  .waf-breadcrumb {
    @extend %c-blue-3-bg-10;
    .breadcrumb {
      &-item {
        &:last-child span {
          @extend %c-white-10;
        }
      }
    }
  }
}
@media screen and (min-width: $tablet-min-width) {
  .waf-playlist-section,
  .waf-playlist-detail {
    .article-section-head {
      max-width: var(--container-max-width);
      margin-right: var(--container-white-space);
    }
  }
  .waf-playlist-section.waf-listing {
    .article-list {
      @include card-count(5, var(--full-space), nowrap);
    }
    .swiper-initialized .article-list {
      gap: 0;
      overflow-x: unset;
    }
  }
  .waf-playlist-detail.waf-listing {
    .swiper {
      &::before,
      &::after {
        content: unset;
      }
    }
    .swiper-button {
      &-prev,
      &-next {
        display: none;
      }
    }
    .article-list {
      flex-direction: row;
      @include card-count(4, var(--full-space));
    }
  }
}